import { defineStore } from 'pinia'
import * as doodles from '@/assets/doodles'

export const useUIStore = defineStore('ui', () => {
  async function showParticles() {
    const { confetti } = await import('@tsparticles/confetti')
    if (new Date().getMonth() >= 11 || new Date().getMonth() <= 1) {
      showSnow(confetti)
    } else {
      showConfetti(confetti)
    }
  }

  function showConfetti(confetti: any) {
    const end = Date.now() + 4000

    ;(function frame() {
      confetti({
        zIndex: 6001,
        particleCount: 2,
        angle: 60,
        spread: 55,
        origin: { x: 0 }
      })

      confetti({
        zIndex: 6001,
        particleCount: 2,
        angle: 120,
        spread: 55,
        origin: { x: 1 }
      })

      if (Date.now() < end) {
        requestAnimationFrame(frame)
      }
    })()
  }

  function showSnow(confetti: any) {
    const duration = 4000
    const animationEnd = Date.now() + duration

    let skew = 1

    function randomInRange(min: number, max: number) {
      return Math.random() * (max - min) + min
    }

    ;(function frame() {
      const timeLeft = animationEnd - Date.now()
      const ticks = Math.max(200, 500 * (timeLeft / duration))

      skew = Math.max(0.8, skew - 0.001)

      confetti({
        zIndex: 6001,
        particleCount: 1,
        startVelocity: 0,
        ticks: ticks,
        origin: {
          x: Math.random(),
          y: Math.random() * skew - 0.2
        },
        colors: ['#ffffff'],
        shapes: ['circle'],
        gravity: randomInRange(0.4, 0.6),
        scalar: randomInRange(0.4, 1),
        drift: randomInRange(-0.4, 0.4)
      })

      if (timeLeft > 0) {
        requestAnimationFrame(frame)
      }
    })()
  }

  function doodleRenderer() {
    setInterval(() => {
      if (Math.random() > 0.175) {
        return
      }

      const doodleContainers = document.querySelectorAll('.doodle-container')
      if (doodleContainers.length === 0) {
        return
      }

      const doodleContainer = doodleContainers[Math.floor(Math.random() * doodleContainers.length)]
      if (doodleContainer.querySelectorAll('.doodle').length > 0) {
        return
      }

      const doodle =
        Object.values(doodles)[Math.floor(Math.random() * Object.values(doodles).length)]
      const doodleTemplate = document.createElement('template')
      doodleTemplate.innerHTML = doodle
      const doodleSvg = doodleTemplate.content.firstElementChild
      if (!doodleSvg) {
        return
      }
      doodleSvg.classList.add('doodle')
      doodleSvg.style.marginTop = doodleContainer.computedStyleMap().get('padding-top').toString()
      doodleSvg.style.marginRight = doodleContainer
        .computedStyleMap()
        .get('padding-right')
        .toString()
      doodleSvg.style.marginBottom = doodleContainer
        .computedStyleMap()
        .get('padding-bottom')
        .toString()
      doodleSvg.style.marginLeft = doodleContainer.computedStyleMap().get('padding-left').toString()
      doodleContainer.prepend(doodleTemplate.content)
      setTimeout(() => {
        try {
          const node = doodleContainer.querySelector(`#${doodleSvg.id}`)
          node?.parentNode?.removeChild(node)
        } catch (error: any) {
          console.error('Failed removing doodle:', error.message)
        }
      }, 12000)
    }, 5000)
  }

  doodleRenderer()

  return {
    showParticles
  }
})
