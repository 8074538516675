import { createApp, nextTick } from "vue";
import { createPinia } from "pinia";
import * as Sentry from "@sentry/vue";
import "@fontsource/barlow/latin.css";
import "@fontsource/open-sans/latin.css";
import { Quasar, Notify, Dialog, AppFullscreen } from "quasar";
import "quasar/src/css/index.sass";
import "quasar/src/css/flex-addon.sass";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/material-icons-outlined/material-icons-outlined.css";
import "@quasar/extras/material-icons-round/material-icons-round.css";

import App from "./App.vue";
import router from "./router";
// @ts-ignore
import createStore from "./store";
import "@/scss/main.scss";

import { initVersionCheck } from "@/vantool/utils/version";

initVersionCheck();

const app = createApp(App);

app.use(router);

router.afterEach((to, from) => {
  // https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  nextTick(() => {
    if (to.meta.title) {
      document.title = to.meta.title.toString();
    }
  });
});

export const store = createStore();
store.router = router;
app.config.globalProperties.$actions = store.actions;
app.config.globalProperties.$state = store.state;

// setupExternalScanningDevice(document, store.state)

const pinia = createPinia();
pinia.use(({ store }) => {
  store.router = router;
});

app.use(pinia);

app.use(Quasar, {
  plugins: {
    Notify,
    Dialog,
    AppFullscreen,
  },
  config: {
    brand: {
      primary: "#feed46",
      dark: "#1d1d1b",
      "dark-page": "#1d1d1b",
    },
    dark: false,
    screen: {
      bodyClasses: true,
    },
  },
});

if (import.meta.env.PROD && !import.meta.env.STAGING) {
  Sentry.init({
    app,
    dsn: "https://69b3225e02d54305a83f232c89979f05@o1243369.ingest.sentry.io/6398708ä",
    environment: "production",
    trackComponents: true,
    logErrors: true,
    integrations: [],
    replaysSessionSampleRate: 0.2,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/.*\.yeply\.((com)|(fi)|(de)|(nl)).*/,
    ],
    profilesSampleRate: 1.0,
  });
}

import("@sentry/vue").then((lazyLoadedSentry) => {
  Sentry.addIntegration(
    lazyLoadedSentry.replayIntegration({
      networkDetailAllowUrls: [window.location.origin],
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
    }),
  );
  Sentry.addIntegration(
    lazyLoadedSentry.vueIntegration({
      trackComponents: true,
    }),
  );
  Sentry.addIntegration(lazyLoadedSentry.browserProfilingIntegration());
  Sentry.addIntegration(lazyLoadedSentry.browserTracingIntegration({ router }));
});

app.mount("#app");
