import { Dialog } from "quasar";

interface VersionInfo {
  build_version: string;
  schema_hash: string;
}

export function initVersionCheck() {
  try {
    installChunkLoadingErrorHandler();
    console.log("Installed chunk loading error handler");
  } catch (error) {
    console.error("Failed to install chunk loading error handler:", error);
  }

  try {
    if (import.meta.env.VITE_VANTOOL_SCHEMA_HASH) {
      startVersionPolling(1000 * 60 * 1);
    } else {
      console.warn(
        "VANTOOL_SCHEMA_HASH is not set. Version checking disabled.",
      );
    }
  } catch (error) {
    console.error("Failed to initialize version polling:", error);
  }
}

function installChunkLoadingErrorHandler() {
  let isShowingChunkError = false;

  window.addEventListener("vite:preloadError", (event) => {
    console.error("Preload error captured:", event);
    const message = event.payload.message;
    const match = message.match(/\/assets\/([^.]+)\.(js|css)+$/);
    const assetPath = match?.[0];
    if (!assetPath) {
      console.warn("No asset path found in preload error message:", message);
      return;
    }

    console.log("Asset path:", assetPath, "Checking if asset exists...");
    const assetUrl = new URL(assetPath, window.location.origin);
    console.log("Asset URL:", assetUrl);

    if (isShowingChunkError) {
      return;
    }

    fetch(assetUrl, { method: "HEAD" })
      .then((response) => {
        if (response.status === 404) {
          isShowingChunkError = true;
          if (
            window.confirm(
              "You are running an outdated version of VanTool. Please reload the page to apply the latest changes and avoid any issues. Do you want to reload now?",
            )
          ) {
            window.location.reload();
          }
          isShowingChunkError = false;
        } else {
          console.warn(
            "Chunk load failed but resource exists (status: " +
              response.status +
              "). Different error cause.",
          );
        }
      })
      .catch((error) => {
        console.error("Failed to verify chunk status:", error);
      });
  });
}

function startVersionPolling(interval: number) {
  let isShowingNewVersionDialog = false;
  const currentSchemaHash = import.meta.env.VITE_VANTOOL_SCHEMA_HASH;

  if (!currentSchemaHash) {
    console.warn(
      "VITE_VANTOOL_SCHEMA_HASH is not set. Version checking disabled.",
    );
    return;
  }

  function onReloadDeclined() {
    console.warn("User declined reload. Not reloading.");
    isShowingNewVersionDialog = false;
    clearInterval(intervalId);
    startVersionPolling(1000 * 60 * 10); // reduce interval to 10 minutes
  }

  const intervalId = setInterval(() => {
    fetch("/api/internal/vantool/version")
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
        return res.json() as Promise<VersionInfo>;
      })
      .then((version) => {
        if (version.schema_hash === currentSchemaHash) {
          return;
        }

        if (isShowingNewVersionDialog) {
          return;
        }

        console.log(
          `Vantool API changed! Current: ${currentSchemaHash}, New: ${version.schema_hash}.`,
        );

        isShowingNewVersionDialog = true;
        const message = "Please reload the page to apply the latest changes.";
        try {
          Dialog.create({
            title: "Important Update Required",
            message,
            class: "bg-primary text-black",
            persistent: true,
            ok: {
              label: "Reload Now",
              color: "black",
              textColor: "primary",
            },
            cancel: {
              label: "Later",
              textColor: "black",
              flat: true,
            },
          })
            .onOk(() => window.location.reload())
            .onCancel(() => onReloadDeclined());
        } catch (error) {
          if (confirm(message)) {
            window.location.reload();
          } else {
            onReloadDeclined();
          }
        }
      })
      .catch((error) => {
        console.error("Failed to poll version:", error);
      });
  }, interval);

  return intervalId;
}
